// units
$unit: 16px;
$innerW: $unit * 52;
$bpipadpro: 1024px;
$bpTb:	767px;
$bpSp:	599px;
$headerH: 74px;
//$spHeaderH:	80px;

// fonts
$sansSerif: -apple-system,BlinkMacSystemFont,"Helvetica Neue","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
$serif:		'Times New Roman',Georgia,"Hiragino Mincho ProN",游明朝,"Yu Mincho",YuMincho,HGS明朝E,serif;
$trajan:	"trajan-pro-3",'Times New Roman',Georgia,"Hiragino Mincho ProN",游明朝,"Yu Mincho",YuMincho,HGS明朝E,serif;
$baskerville: baskerville-urw, 'Times New Roman',Georgia,"Hiragino Mincho ProN",游明朝,"Yu Mincho",YuMincho,HGS明朝E,serif;

$nimbus: nimbus-sans, -apple-system,BlinkMacSystemFont,"Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
$quasimoda: quasimoda, -apple-system,BlinkMacSystemFont,"Helvetica Neue","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;

$serifJp: "A1 Mincho","Hiragino Mincho ProN",游明朝,"Yu Mincho",YuMincho,HGS明朝E,serif;
$sansSerifJp: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", nimbus-sans, -apple-system,BlinkMacSystemFont,"Helvetica Neue", sans-serif;

/*
Baskerville Display PT Regular
font-family: baskerville-display-pt, serif;
font-weight: 400;
font-style: normal;

Baskerville Display PT Bold
font-family: baskerville-display-pt, serif;
font-weight: 700;
font-style: normal;

Baskerville Poster PT Regular
font-family: baskerville-poster-pt, serif;
font-weight: 400;
font-style: normal;

Nimbus Sans Regular
font-family: nimbus-sans, sans-serif;
*/

//$fontM:		$unit;			// 16px;
$fontM:		4vw;
$fontL:		$fontM * 1.25;	// 20px
$fontXl:	$fontM * 1.6;	// 24px
$fontS:		$fontM * .875;	// 13px
$fontXs:	$fontM * .6875;	// 11px

// colors
$bk01:	rgba(0, 0, 0, 1);		// キンクロ	
$rd01: #FF4528;		// spread red
// $rd01: #ea5720;
$gy01: #D1D1D1;		// spread gray
$gy02: #DDD;		// spread light gray

// transition
$tr05s:	all .5s ease;
$tr1s:	all 1s ease;
$tr2s:	all 2s ease;
