@mixin clearfix {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin trns($property: all, $speed: .5s, $easing: ease) {
	transition: $property $speed $easing;
}

@mixin serifBasic {
	font-family: $baskerville;
	line-height: 1.5;
// 	letter-spacing: .075vw;
	// letter-spacing: .2rem;
}

@mixin serifBasicJp {
	font-family: $serifJp;
	line-height: 1.5;
	font-size: 1.75rem;
// 	letter-spacing: .075vw;
	@media (max-width: $bpSp) {
		font-size: 1rem;
	}
}