body:lang(ja) {
	font-family: $sansSerifJp;
	font-weight: 200;

	.intro {
		@include serifBasicJp;
		font-weight: normal;
		line-height: 2;
		font-size: 1.55rem;
		@media (max-width: $bpSp) {
			font-size: .95rem;
		}
	}

	.single {
		p, 
		.content > div {
			font-size: .8rem;
			line-height: 1.875;
		}

		.intro {
			@extend .intro;
		}
	
		.achievement {

			h2,
			p,
			div {
				font-size: 1rem;
			}

			.txt_s {
				h2,
				p,
				div {
					font-size: .75rem;
				}
			}
		}
	}

	header {
		.head_items {
			a {
				font-family: $nimbus;
				// font-weight: 400;
				font-size: .825rem;
			}

			.lang-sel {
				font-weight: 300;
			}
		}
	}

	.qa {
		dl {
			.qa_a {
				.dd_txt {
					font-size: 1.85vw;
					line-height: 1.75;

					@media (max-width: $bpSp) {
						font-size: 3.5vw;
					}
				}
			}
		}
	}

	&#register {
		.wrapper2 {
			div {}
		}
	}
}