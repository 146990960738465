body {
	font-family: $nimbus;
	font-weight: 300;
	background: $gy01;
	line-height: 1.135;
	font-size: $unit * 1.5;
	letter-spacing: 0.05rem;

	* {
		opacity: 0;

		&:not(.slick-track) {
			transition: $tr05s;
		}
	}

	@media (max-width: $bpSp) {
		font-size: $unit;
	}

	&.loaded {
		* {
			opacity: 1;
		}
	}

	&.noscroll {
		overflow: hidden;
	}
}

img {
	height: auto;
}

a {
	color: $bk01;
	text-decoration: none;
	transition-duration: .25s;

	&:hover {
		color: #666;
		transition-duration: .25s;
	}
}

strong {
	font-weight: bolder;
}

.sp {
	@media (min-width: $bpSp + 1) {
		display: none !important;
	}
}

.tb {
	@media (min-width: $bpTb + 1) {
		display: none !important;
	}
}

.ipp {
	@media (min-width: $bpipadpro + 1) {
		display: none !important;
	}
}

.nosp {
	@media (max-width: $bpSp) {
		display: none !important;
	}
}

.notb {
	@media (max-width: $bpTb) {
		display: none !important;
	}
}

.noipp {
	@media (max-width: $bpipadpro) {
		display: none !important;
	}
}

.wrapper {
	padding: $unit * 3;

	@media (max-width: $bpSp) {
		padding: $unit * 3;
	}

	@media (max-width: $bpSp) {
		padding: $unit * 2 $unit * 3;

	}
}

.wrapper2 {
	max-width: $unit * 80;
	margin: 0 auto;
	// padding: $unit * 4 $unit * 6;
	padding: 1.5rem 3.75rem;

	@media (max-width: $bpSp) {
		padding: 1.5rem;
	}
}

.intro {
	@include serifBasic;
	font-size: 2rem; // 36px

	@media (max-width: $bpTb) {
		font-size: 5vw;
		// padding: 4vw 0;
	}
}

header {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	background: $gy01;

	.wrapper2 {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (min-width: $bpTb + 1) {
			padding-bottom: 1rem;
		}
	}

	.head_items {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;

		// width: $unit * 4.5;
		a {
			font-weight: 300;
			font-size: .875rem;
			vertical-align: middle;

			&:hover {
				color: #fff;
			}
		}

		.hamburger {
			width: $unit * 1.5;
			height: $unit;
			margin: 0 0 0 $unit;
			position: relative;
			z-index: 16;

			@media (max-width: $bpTb) {
				width: 14px;
				height: 16px;
			}

			span {
				width: 100%;
				height: 1px;
				background: #000;
				display: block;
				position: absolute;

				&.hamburger_top {
					top: 0;
				}

				&.hamburger_middle {
					top: 50%;
					transform: translateY(-50%);
					opacity: 1;
				}

				&.hamburger_bottom {
					bottom: 0;
				}
			}

			&.hamburger_toggle {
				span {
					background: #FF4528;

					&.hamburger_top {
						transform: translateY($unit / 2) rotate(45deg);

						@media (max-width: $bpTb) {
							top: 0px;
						}
					}

					&.hamburger_middle {
						opacity: 0;
					}

					&.hamburger_bottom {
						transform: translateY($unit / -2) rotate(-45deg);

						@media (max-width: $bpTb) {
							bottom: -0.55px;
						}
					}
				}
			}
		}
	}

	.nav_overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100vh;
		z-index: 14;
		display: none;

		&.nav_overlay_act {
			display: block;
		}
	}

	.lang-sel {
		position: relative;
		top: .175rem;
		z-index: 16;

		&.lang_sel_color {
			@media (max-width: $bpSp) {
				color: $rd01;
				transition: .8s;
			}
		}

		@media (max-width: $bpSp) {
			font-size: 1rem;
			top: 0;
			margin-bottom: 0;
			font-weight: 300;
			// font-size: 4vw;
		}
	}

	.nav_pc {
		$ulW: 20rem; // メニューの数と間のサイズに合わせて調整する
		width: 100%;
		margin: 0 calc((100% - #{$ulW}) / 100 * 25) 0 calc((100% - #{$ulW}) / 100 * 40);

		ul {
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			>* {}
		}
	}

	.toggle {
		cursor: pointer;

		.hamburger {
			margin: 0 2.4vw 0 -4vw;

			@media (max-width: $bpTb) {
				margin: 0 1vw 0 -4vw;
			}

			@media (max-width: $bpSp) {
				margin: 0 1vw 0 -4vw;
			}

			img {
				width: 2vw;
				max-width: 28px;
				transition: $tr05s;

				@media (max-width: $bpTb) {
					width: 2vw;
				}

				@media (max-width: $bpSp) {
					width: 3vw;
				}
			}
		}

		&.toggled,
		&:hover {
			.hamburger {
				img {
					transform: scaleX(1.5);
				}
			}
		}

		&.toggled {
			.hamburger {
				img {
					transition: none;
				}
			}
		}
	}
}

// contents nav
.cnav {
	font-family: $nimbus;
	// font-size: 0.875rem;
	font-size: 0.825rem;
	font-weight: 300;
	padding-top: 1rem;
	padding-bottom: 1rem;
	transform: translateX(-1rem);
	text-transform: uppercase;
	text-align: center;

	>* {
		margin: .5rem .75rem;
		display: inline-block;
	}

	@media (max-width: $bpTb) {
		font-size: .825rem;
		// padding-top: inherit;
		// padding-bottom: inherit;
		transform: translateX(-.5rem);

		>* {
			margin: .25rem .5rem;
			display: inline-block;
		}
	}

	@media (max-width: $bpSp) {
		// display: none;
	}
}


.nav_sp,
footer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background: #fff;
	flex-wrap: wrap;
	text-transform: uppercase;
	font-family: $nimbus;

	.sns {
		margin-top: -2.5em;
		width: 100%;

		@media (max-width: $bpTb) {
			margin: 0 0 2em;
		}

		a {
			margin-right: .25em;

			img {
				// vertical-align: baseline;
				height: $unit;
			}

		}
	}

	.copyright {
		font-size: 11px;
	}

	.wrapper2 {
		width: 100%;
		display: flex;
		justify-content: space-between;
		// justify-content: flex-end;
		flex-wrap: wrap;
		padding: 3.5rem 3.75rem;

		@media (max-width: $bpSp) {
			padding: 3rem 1.5rem 1.5rem;
		}

		section {
			width: calc((100% - (25% + 20%)) / 4);
			margin-bottom: 0;

			@media (max-width: $bpipadpro) {
				// width: auto;
			}

			@media (max-width: $bpTb) {
				width: 100%;
				margin-bottom: 2em;
			}

			&.footer_profile {
				width: 35%;
				padding: 0 10% 0 0;
				display: flex;
				flex-wrap: wrap;
				align-content: space-between;

				@media (max-width: $bpipadpro) {
					width: 25%;
				}

				@media (max-width: $bpTb) {
					width: 100%;
					margin-bottom: 2em;
				}
			}

			.footer_nav_work {
				display: block;
				font-size: 0.875rem;

				// font-weight: 400;
				+* {
					margin-top: 1.25rem;
				}
			}

			&:last-of-type {
				// width: 2.5rem
			}

			.work_contents_link2 {
				@extend .footer_nav_work;
				font-size: .775rem;
			}

			.work_contents_link {
				display: block;
				// margin-left: 1.5em;
				font-size: .775rem;

				&:not(:last-child) {
					margin-bottom: .5rem;
				}

				@media (max-width: $bpipadpro) {
					text-indent: 0;
				}

				@media (max-width: $bpTb) {
					margin-left: 1.5em;
				}
			}

			&.footer_works_last {
				position: relative;

				.copyright {
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}

			&.footer_works_last {
				@media (max-width: $bpSp) {
					//width: 100%;
					margin-bottom: 0;
				}
			}

		}
	}
}

.nav_sp {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 15;
	width: 0;
	min-height: 100vh;
	background: $bk01;
	overflow: hidden;

	.wrapper2 {
		align-items: flex-start;
	}

	a {
		color: $rd01;
	}

	&.open {
		right: 0;
		width: calc((100% / 5) * 2);

		@media (max-width: $bpTb) {
			width: 100%;
		}
	}
}

footer {
	margin-top: 9rem;

	@media (max-width: $bpTb) {
		margin-top: 4.5rem;
	}
}

.logo_main {
	width: 10rem;
	transform: translateY(-.0125rem);

	@media (max-width: $bpTb) {
		width: 16vw;
		max-width: 130px;
		// transform: translateY(0px);
	}

	@media (max-width: $bpSp) {
		width: 38vw;
		max-width: 108px;
	}
}

.work_cat_title {
	font-family: $quasimoda;
	font-size: $unit * 2 + 4px; // ロゴと同じ大きさ36px
	letter-spacing: 0, 1rem;
	margin: 0 auto $unit * -6;

	@media (max-width: $bpTb) {
		margin: 0 auto $unit * -2 + 4;
		font-size: 4.35vw;
	}

	@media (max-width: $bpSp) {
		font-size: 5.35vw;
	}

	a {
		&:not(:first-child) {
			&:before {
				content: "/";
				margin: 0 $unit;
				color: #000;
				transition: none;
			}

			&:hover {
				&::before {
					color: #000;
				}
			}
		}
	}
}

// work list
.grid1 {
	display: grid;
	grid-template-columns: repeat(3, calc((100% / 3) - 1.133rem));
	column-gap: 1.75rem;
	row-gap: 3rem;
	width: 100%;

	@media (max-width: $bpSp) {
		grid-template-columns: repeat(1, 100%);
		column-gap: 0;
	}

	.grid_item {
		margin: 0;

		.work_title {
			font-family: $quasimoda;
			font-size: 1rem;
			letter-spacing: 0.04em;
			margin: 1.15rem 0 0;
		}

		.images {
			img {
				object-fit: cover;
				aspect-ratio: 369 / 235;
			}
		}
	}
}

.list_item {

	h1 {
		font-family: $quasimoda;
		// font-size: 4.25rem;
		font-size: 4.1rem;
		font-weight: 400;
		line-height: 1;
		// letter-spacing: 0.1rem;
		letter-spacing: 0.009em;
		position: absolute;
		top: -3.15rem;
		left: 0;
		z-index: 5;
		width: 100%;
		padding: 0 calc(((100% - 40rem) / 100 * 25) - 3rem) 0 calc(((100% - 40rem) / 100 * 40) + 6.25rem);
		text-align: center;

		@media (max-width: $bpSp) {
			// font-size: 7vw;
			// // top: -5vw;
			// top: -5.5vw;
			// left: -0.175rem;
			// width: 100%;
			display: none;
		}

		+* {
			margin-top: -1.5vw;

			@media (max-width: $bpSp) {
				margin-top: -1.5vw * 2;
			}
		}

		// &.work_title {
		// 	font-size: $unit * 1.5;
		// 	line-height: 1.35;
		// 	letter-spacing: 0.009em;

		// 	@media (max-width: $bpTb) {
		// 		font-size: 3vw;
		// 	}

		// 	@media (max-width: $bpSp) {
		// 		font-size: 4vw;
		// 	}

		// 	+*,
		// 	+*+.right {
		// 		margin-top: $unit;
		// 		text-align: left;
		// 	}
		// }
	}

	.work_title {
		font-family: $quasimoda;
		font-size: 1rem;
		letter-spacing: 0.04em;
		margin: 1.15rem 0 0;

	}

	a {
		display: block;
		position: relative;
		// overflow: hidden;

		.trim {
			transition: $tr05s;
			position: relative;
			top: 0;
		}

		&:hover {
			.trim {
				top: -5px;
			}

			h1 {
				color: #fff;
			}
		}
	}

	.images {
		img {
			transition: filter 0.3s;

			&.lazyestload {
				width: 100%;
				filter: blur(7px);
			}
		}
	}

}

.single {
	// font-size: $unit * 1.5;
	font-size: 1rem;
	line-height: 1.5;

	h2 {
		font-weight: 400;
		letter-spacing: 0.04em;
		font-family: $quasimoda;
		margin: 1.5rem 0;

		@media (min-width: $bpTb + 1) {
			font-weight: 300;
			font-size: 1.4rem;
			line-height: 1.5;
		}

		&:first-child {
			margin: 0 0 1.5rem;
		}
	}

	.block {
		&:not(:first-child) {
			margin: $unit * -4 0 0;
			padding: $unit * 8 0 0;
			width: 50%;

			@media (max-width: $bpTb) {
				width: 100%;
			}
		}

		h1 {
			margin: 0 0 $unit * 2;
		}

		@include clearfix;
	}

	.flex {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		>* {
			width: calc(50% - #{$unit * 1.5});

			@media (max-width: $bpTb) {
				width: 100%;
			}
		}
	}

	.indent1 {
		@media (min-width: $bpTb + 1) {
			transform: translate(-7.5rem, 0.25rem);
		}
	}

	.left {
		float: left;
		margin: 0;
		width: 5.5rem;
	}

	.right {
		margin: 0 0 0 7.5rem;
	}

	p,
	.content>div {
		margin: 0 0 1rem;
		// font-family: $nimbus;
		font-size: .875rem;
		letter-spacing: .04em;
		line-height: 1.6;
	}

	.content>div {
		&:last-of-type {
			&:not(.right, .left) {
				margin: 0 0 2rem;
			}
		}
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}

	.intro {
		@include serifBasic;
		font-size: 2rem; // 36px

		@media (max-width: $bpTb) {
			font-size: 5vw;
			// padding: 4vw 0;
		}
	}

	// .ja {
	// 	// @include serifBasic;
	// 	font-size: 83.5%;
	// 	line-height: 1.95;
	// 	letter-spacing: 0.075rem;
	// }

	.images {
		&:not(:first-child) {
			margin: 1.5rem 0 0;
		}

		img {
			width: 100%;
			transition: filter 0.3s;

			&:not(:first-child) {
				margin: 1.5rem 0 0;
			}

			&.lazyestload {
				width: 100%;
				filter: blur(7px);
			}

			// @media (max-width: $bpTb) {
			// 	margin-top: 4vw;
			// }

			// @media (max-width: $bpSp) {
			// 	margin-top: 5vw;
			// }
		}

		+.videos {
			margin: 1.5rem 0 0;
		}

	}

	.img {
		img {
			width: 100%;
		}
	}

	.achievement {
		margin-top: 3.75rem;

		@media (max-width: $bpTb) {
			margin-top: 0;
		}

		>section {
			margin-top: 4rem;

			@media (max-width: $bpTb) {
				margin-top: 1.5rem;
			}

			>* {
				width: calc(66.6% - 1rem);

				&:first-child {
					width: calc(33.3% - 2rem);
				}

				@media (max-width: $bpTb) {
					width: 100%;
					margin: 1.5rem 0 0;

					&:first-child {
						width: 100%;
					}
				}
			}
		}

		h2 {
			font-family: $nimbus;
		}

		h2,
		div,
		p {
			font-size: 1.125rem;
			line-height: 1.75;
			letter-spacing: .04em;

			&:last-child {
				&:first-child {
					margin: 0;
				}
			}

			@media (max-width: $bpTb) {
				font-size: 1rem;
			}
		}

		.txt_s {

			h2,
			div,
			p {
				font-size: 0.875rem;
				line-height: 1.5;
				margin-bottom: .65rem;
			}
		}
	}

	/*------------------------------------
		newsletter
------------------------------------*/

	.newsletter {
		margin: 3rem auto;

		@media (max-width: $bpSp) {
			width: 100%;
		}

		h2 {
			font-size: 1.125rem;
			line-height: 1.75;

			@media (max-width: $bpTb) {
				font-size: 1rem;
			}
		}

		.flex {
			>* {
				width: calc(66.6% - 1rem);

				br {
					display: none;
				}

				&:first-child {
					width: 33.3%;
				}

				&:last-child {
					padding: 0 calc(33.3% + 1rem) 1.5rem 0;
					margin: -2.5rem 0 0;
				}

				div {
					padding: 0;
				}

				@media (max-width: $bpTb) {
					width: 100%;
					margin: 1.5rem 0 0;

					&:first-child {
						width: 100%;
					}

					&:last-child {
						padding: 0;
						margin: 0;
					}
				}
			}
		}

		input {
			border: none !important;
			border-bottom: 1px solid #707070 !important;
			font-family: $nimbus !important;
			font-size: .875rem !important;
			font-weight: 300 !important;
			letter-spacing: 0.07rem !important;
			margin: 0 !important;
			padding: 1.25rem 0 !important;

			@media (max-width: $bpSp) {
				// font-size: 6vw !important;
			}
		}

		fieldset {
			padding: 0 !important;

			div {
				width: 100% !important;
				background-color: rgba(0, 0, 0, 0) !important;
				color: #000 !important;
				margin: .5rem 0 !important;
				padding: .75rem 0 !important;
				font-family: $nimbus !important;
				text-align: left !important;
				// padding: 1rem 0 !important;
				font-size: .875rem !important;
				// letter-spacing: .1rem !important;

				@media (max-width: $bpSp) {
					// width: 63vw !important;
					// font-size: 6vw !important;
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0) !important;
				}
			}

			input {
				// text-align: center;
			}
		}
	}

	#register {
		height: 100vh;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;

		.wrapper2 {
			margin: 0 auto;
			margin-top: 0 !important;

			&.single {
				@media (max-width: $bpSp) {
					padding: 0;
				}
			}
		}
	}


	&.news {
		padding-top: 3rem;

		h2 {
			font-family: $nimbus;
		}

		.child {
			margin-bottom: 4rem;

			@media (min-width: $bpTb + 1) {
				display: flex;
				margin-bottom: 3rem;
			}

			.date {
				// font-size: .85rem;
				margin-top: .325rem;
				// font-weight: 400;
				width: 15%;

				@media (max-width: $bpTb) {
					width: 100%;
					margin-bottom: 1.5rem;
				}
			}

			.text {
				width: 40%;

				@media (max-width: $bpTb) {
					width: 100%;
					margin-bottom: 4vw;
				}

				@media (max-width: $bpSp) {
					margin-bottom: 6vw;
				}


				.description {
					// font-family: $serif;
				}
			}

			.img {
				width: 45%;
				padding-left: 3rem;
				transform: translateY(5px);

				@media (max-width: $bpTb) {
					width: 100%;
					padding-left: 0;
				}

				img {
					width: 100%;
				}
			}

			p,
			div {
				margin-bottom: 1.5rem;
				// font-family: $quasimoda;
				// letter-spacing: 0.075rem;
				// font-size: $unit;

				&.ja {
					font-family: $sansSerifJp;
					// font-size: #{$unit * .92};
					// line-height: 1.675;
					// letter-spacing: 0.075rem;
				}


				@media (max-width: $bpTb) {
					// margin-bottom: 3vw;
				}

				@media (max-width: $bpSp) {
					// font-size: 4vw;
					// margin-bottom: 4vw;

					&.ja {
						// font-size: #{4vw * .92};
					}
				}

				a {
					text-decoration: underline;

				}

				// &.ja {
				// 		font-size: 83.5%;
				// 		line-height: 1.95;
				// 		letter-spacing: 0.075rem;	
				// }
			}
		}
	}

}


// .home,
// .work_archive {
.home {
	background: $rd01;
	overflow: hidden;

	header {
		background: $rd01;
	}

	.loading {
		opacity: 1;
		background: url(../img/logo_spread_bk.svg) no-repeat 50% 50%;
		background-size: 48%;
		// position: absolute;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		transition: $tr05s;
	}

	&.loaded {
		overflow: auto;

		.loading {
			background-position-y: 52.5%;
			opacity: 0;
		}
	}

	#background,
	.background {
		position: relative;
		padding: calc(360 / 566 * 100%) 0 0;
		height: 0;
		overflow: hidden;
		max-width: 100%;
		margin-bottom: $unit * 3;

		@media (max-width: $bpTb) {
			margin-bottom: $unit * 2.5;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.list_item {
		margin-top: 9rem;

		@media (max-width: $bpTb) {
			margin-top: 5rem;
		}

		@media (max-width: $bpSp) {
			margin-top: 1rem;
		}

		// SP比較用
		@media (max-width: $bpSp) {

			&:nth-of-type(4),
			&:nth-of-type(5),
			&:nth-of-type(6) {
				h1 {
					text-align: left;
				}
			}

			&:nth-of-type(7),
			&:nth-of-type(8),
			&:nth-of-type(9) {
				margin-top: -.25rem;

				h1 {
					text-align: left;
					position: static;
					margin-bottom: .75rem;

					+* {
						margin-top: 0;
					}
				}
			}
		}
	}
}

.biography {
	.intro {
		+* {
			margin: 3rem 0 0;

			&.flex {
				margin: 4rem 0 0;

				@media (max-width: $bpTb) {
					margin: 3rem 0 0;
				}
			}
		}
	}

	.flex {
		>* {
			width: calc(33.3% - 2rem);
			font-size: 0.875rem;

			@media (max-width: $bpTb) {
				width: 100%;

				&:not(:first-child) {
					margin: 1.5rem 0 0;
				}
			}
		}
	}

	img {
		width: 100%;
		object-fit: cover;
	}
}

.contact {
	.flex {
		>* {
			width: calc(33.3% - 2rem);
			font-size: 0.875rem;

			@media (max-width: $bpTb) {
				width: 100%;
				margin: 1.5rem 0 0;
			}
		}

		h2 {
			font-size: 1.125rem;
			line-height: 1.75;

			@media (max-width: $bpTb) {
				font-size: 1rem;
			}
		}
	}
}

.pagenavi {
	display: none;
}

.archive_work {
	font-size: 4vw;
	letter-spacing: 0.1vw;

	.group {
		overflow-x: hidden;

		@media (max-width: $bpTb) {
			width: 100%;
		}

		>nav {
			padding: 0 0 0 4vw;

			p,
			ul {
				width: 100%;
			}

			p {
				@media (min-width: $bpTb + 1) {
					width: 45%;
				}
			}

			ul {
				display: none;
				overflow: scroll;
				transition: $tr05s;
				margin-top: .5rem;

				@media (min-width: $bpTb + 1) {
					font-size: 3.4vw;
					position: absolute;
					top: 0;
					right: 0;
					z-index: 20;
					padding-right: 6vw;
					width: 55%;
					height: calc(100vh - .5rem);
				}

				@media (max-width: $bpTb) {
					width: 100%;
					margin: 0 0 0 4vw;
					display: block;
					max-height: 0;
					opacity: 0;
				}
			}

			@media (min-width: $bpTb + 1) {
				&:hover {
					ul {
						display: block;
					}

					p {
						color: #fff;
					}
				}

				a {
					&:hover {
						color: #fff;
					}
				}
			}

			@media (max-width: $bpTb) {
				&.selected {
					ul {
						opacity: 1;
						max-height: 100vh;
						transition: 1s all ease-out;
					}
				}
			}
		}
	}

	h1 {
		// .nav_sp p {
		cursor: pointer;
		margin-bottom: $unit * 2;

		@media (max-width: $bpTb) {
			margin-bottom: 1.5vw;
		}

		@media (max-width: $bpSp) {
			margin-bottom: 3vw;
		}
	}

	@media (max-width: $bpTb) {
		&.second {
			.group {
				>nav {

					//transform: translateX(-100%);
					p {
						//display: none;
					}

					ul {
						//max-height: 200vh;
					}
				}
			}
		}
	}
}

// .work_archive {
// 	@media (min-width: $bpTb + 1) {
// 		overflow: hidden;

// 		footer {
// 			position: fixed;
// 			bottom: 0;
// 			width: 100%;
// 		}
// 	}
// }

a.more {
	font-family: $quasimoda;
	color: #9fa0a0;
	display: block;
	// margin-left: .5rem;

	&:hover {
		color: $bk01;
	}
}

span.more {
	display: none;

	&.visible {
		display: inline !important;
		animation-duration: 3s;
		animation-name: fadeIn;

		+.more {
			margin-top: 1rem;
		}
	}
}

.video {
	padding-bottom: 56.25%;
	/* for 16:9 ratio */
	// padding-bottom: 63.59375%;
	/*640px x 407px*/
	// padding-top: 25px;
	position: relative;
	height: 0;
	background: #000;

	>* {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@keyframes fadeIn {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: inline !important;
		opacity: 0;
	}

	100% {
		display: inline !important;
		opacity: 1;
	}
}

//Q&A
.qa {
	.qa_lists {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@media (max-width: 800px) {
			display: block;
		}

		.qa_block {
			width: calc(50% - #{$unit * 3 });
			min-height: 8rem;

			@media (max-width: 800px) {
				width: 100%;
				min-height: auto;
				margin-bottom: 32px;
			}

			.qa_q {
				margin: $unit * 6 0 $unit;
				position: relative;
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				height: auto;

				&:first-child {
					margin-top: 0;
				}

				&:hover {
					.qa_arrow {
						transform: translateY($unit / 2);
					}
				}

				.dt_q {
					display: inline-block;
					@include serifBasic;
					font-size: $unit * 2.5;
					text-align: right;
					vertical-align: top;
					transform: translate(0, $unit * -1, );

					@media (max-width: $bpSp) {
						font-size: 3.96vw;
						width: 6vw;
						transform: translate(-5px, -3px, );
					}
				}

				.dt_txt {
					display: inline-block;
					font-size: $unit * 1.125;
					letter-spacing: 0.1rem;
					width: calc(100% - #{$unit * 8});
					text-transform: uppercase;

					@media (max-width: $bpSp) {
						font-size: 3.96vw;
						width: calc(100% - 10vw);
					}
				}

				.qa_arrow {
					position: relative;
					top: 0;
					width: $unit * 1.5;
					right: 0;
					align-self: self-start;

					&.qa_arrow_active {
						transform: rotate(180deg);
					}

					@media (max-width: 800px) {
						top: .5vh;
						width: 3vw;
					}

				}
			}

			.qa_a {
				display: none;
				margin-bottom: $unit * 2;
				transition-property: none;

				//width: 90%;
				//padding-left: 5.5vw;
				//text-indent: -5.5vw;
				span {
					///display: inline-block;
					//margin-right: .3vw;
					//margin-left: 1.7vw;

				}

				// .dd_a {
				// 	display: inline-block;
				// 	width: 4.35vw;
				// 	text-align: right;
				// 	margin-right: 2.4vw;
				// 	vertical-align: top;
				// 	@media (max-width: $bpSp) {
				// 		font-size: 3.96vw;
				// 		width: 8vw;
				// 		margin-right: 1vw;
				// 	}
				// }
				.dd_txt {
					font-size: $unit;
					letter-spacing: .1rem;
					display: inline-block;
					padding: 0 0 0 $unit * 4.5;

					@media (max-width: $bpSp) {
						font-size: 3.96vw;
						width: calc(100% - 11vw);
						padding-left: 9vw;
					}
				}
			}
		}
	}
}