@charset "UTF-8";
/*
Baskerville Display PT Regular
font-family: baskerville-display-pt, serif;
font-weight: 400;
font-style: normal;

Baskerville Display PT Bold
font-family: baskerville-display-pt, serif;
font-weight: 700;
font-style: normal;

Baskerville Poster PT Regular
font-family: baskerville-poster-pt, serif;
font-weight: 400;
font-style: normal;

Nimbus Sans Regular
font-family: nimbus-sans, sans-serif;
*/
/*===== BASE =====*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
/* HTML5 display-role reset for older browsers */
html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1;
}

div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

applet {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

object {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h1 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h2 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h4 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h5 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;
}
blockquote:before {
  content: "";
  content: none;
}
blockquote:after {
  content: "";
  content: none;
}

pre {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

abbr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

acronym {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

address {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

big {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

cite {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

code {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

del {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

dfn {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

em {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

ins {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

kbd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

q {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;
}
q:before {
  content: "";
  content: none;
}
q:after {
  content: "";
  content: none;
}

s {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

samp {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

small {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

strike {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

strong {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

sub {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

sup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

tt {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

var {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

b {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

u {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

i {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

center {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

dl {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

dt {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

dd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ol {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

form {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

label {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

legend {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

tbody {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

tfoot {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

thead {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

tr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

th {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

aside {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

details {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

embed {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

figure {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

footer {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

header {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

hgroup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

menu {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

nav {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

output {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ruby {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

time {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

mark {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

audio {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clear {
  clear: both;
}

.clearfix {
  *zoom: 1;
}
.clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  content: " ";
  display: table;
  clear: both;
}

body {
  font-family: nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  background: #D1D1D1;
  line-height: 1.135;
  font-size: 24px;
  letter-spacing: 0.05rem;
}
body * {
  opacity: 0;
}
body *:not(.slick-track) {
  transition: all 0.5s ease;
}
@media (max-width: 599px) {
  body {
    font-size: 16px;
  }
}
body.loaded * {
  opacity: 1;
}
body.noscroll {
  overflow: hidden;
}

img {
  height: auto;
}

a {
  color: black;
  text-decoration: none;
  transition-duration: 0.25s;
}
a:hover {
  color: #666;
  transition-duration: 0.25s;
}

strong {
  font-weight: bolder;
}

@media (min-width: 600px) {
  .sp {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .tb {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .ipp {
    display: none !important;
  }
}

@media (max-width: 599px) {
  .nosp {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .notb {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .noipp {
    display: none !important;
  }
}

.wrapper {
  padding: 48px;
}
@media (max-width: 599px) {
  .wrapper {
    padding: 48px;
  }
}
@media (max-width: 599px) {
  .wrapper {
    padding: 32px 48px;
  }
}

.wrapper2 {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1.5rem 3.75rem;
}
@media (max-width: 599px) {
  .wrapper2 {
    padding: 1.5rem;
  }
}

.intro, body:lang(ja) .single .intro {
  font-family: baskerville-urw, "Times New Roman", Georgia, "Hiragino Mincho ProN", 游明朝, "Yu Mincho", YuMincho, HGS明朝E, serif;
  line-height: 1.5;
  font-size: 2rem;
}
@media (max-width: 767px) {
  .intro, body:lang(ja) .single .intro {
    font-size: 5vw;
  }
}

header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background: #D1D1D1;
}
header .wrapper2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 768px) {
  header .wrapper2 {
    padding-bottom: 1rem;
  }
}
header .head_items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
header .head_items a {
  font-weight: 300;
  font-size: 0.875rem;
  vertical-align: middle;
}
header .head_items a:hover {
  color: #fff;
}
header .head_items .hamburger {
  width: 24px;
  height: 16px;
  margin: 0 0 0 16px;
  position: relative;
  z-index: 16;
}
@media (max-width: 767px) {
  header .head_items .hamburger {
    width: 14px;
    height: 16px;
  }
}
header .head_items .hamburger span {
  width: 100%;
  height: 1px;
  background: #000;
  display: block;
  position: absolute;
}
header .head_items .hamburger span.hamburger_top {
  top: 0;
}
header .head_items .hamburger span.hamburger_middle {
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}
header .head_items .hamburger span.hamburger_bottom {
  bottom: 0;
}
header .head_items .hamburger.hamburger_toggle span {
  background: #FF4528;
}
header .head_items .hamburger.hamburger_toggle span.hamburger_top {
  transform: translateY(8px) rotate(45deg);
}
@media (max-width: 767px) {
  header .head_items .hamburger.hamburger_toggle span.hamburger_top {
    top: 0px;
  }
}
header .head_items .hamburger.hamburger_toggle span.hamburger_middle {
  opacity: 0;
}
header .head_items .hamburger.hamburger_toggle span.hamburger_bottom {
  transform: translateY(-8px) rotate(-45deg);
}
@media (max-width: 767px) {
  header .head_items .hamburger.hamburger_toggle span.hamburger_bottom {
    bottom: -0.55px;
  }
}
header .nav_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 14;
  display: none;
}
header .nav_overlay.nav_overlay_act {
  display: block;
}
header .lang-sel {
  position: relative;
  top: 0.175rem;
  z-index: 16;
}
@media (max-width: 599px) {
  header .lang-sel.lang_sel_color {
    color: #FF4528;
    transition: 0.8s;
  }
}
@media (max-width: 599px) {
  header .lang-sel {
    font-size: 1rem;
    top: 0;
    margin-bottom: 0;
    font-weight: 300;
  }
}
header .nav_pc {
  width: 100%;
  margin: 0 calc((100% - 20rem) / 100 * 25) 0 calc((100% - 20rem) / 100 * 40);
}
header .nav_pc ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
header .toggle {
  cursor: pointer;
}
header .toggle .hamburger {
  margin: 0 2.4vw 0 -4vw;
}
@media (max-width: 767px) {
  header .toggle .hamburger {
    margin: 0 1vw 0 -4vw;
  }
}
@media (max-width: 599px) {
  header .toggle .hamburger {
    margin: 0 1vw 0 -4vw;
  }
}
header .toggle .hamburger img {
  width: 2vw;
  max-width: 28px;
  transition: all 0.5s ease;
}
@media (max-width: 767px) {
  header .toggle .hamburger img {
    width: 2vw;
  }
}
@media (max-width: 599px) {
  header .toggle .hamburger img {
    width: 3vw;
  }
}
header .toggle.toggled .hamburger img, header .toggle:hover .hamburger img {
  transform: scaleX(1.5);
}
header .toggle.toggled .hamburger img {
  transition: none;
}

.cnav {
  font-family: nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 0.825rem;
  font-weight: 300;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transform: translateX(-1rem);
  text-transform: uppercase;
  text-align: center;
}
.cnav > * {
  margin: 0.5rem 0.75rem;
  display: inline-block;
}
@media (max-width: 767px) {
  .cnav {
    font-size: 0.825rem;
    transform: translateX(-0.5rem);
  }
  .cnav > * {
    margin: 0.25rem 0.5rem;
    display: inline-block;
  }
}
.nav_sp,
footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-family: nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
}
.nav_sp .sns,
footer .sns {
  margin-top: -2.5em;
  width: 100%;
}
@media (max-width: 767px) {
  .nav_sp .sns,
footer .sns {
    margin: 0 0 2em;
  }
}
.nav_sp .sns a,
footer .sns a {
  margin-right: 0.25em;
}
.nav_sp .sns a img,
footer .sns a img {
  height: 16px;
}
.nav_sp .copyright,
footer .copyright {
  font-size: 11px;
}
.nav_sp .wrapper2,
footer .wrapper2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3.5rem 3.75rem;
}
@media (max-width: 599px) {
  .nav_sp .wrapper2,
footer .wrapper2 {
    padding: 3rem 1.5rem 1.5rem;
  }
}
.nav_sp .wrapper2 section,
footer .wrapper2 section {
  width: calc((100% - (25% + 20%)) / 4);
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .nav_sp .wrapper2 section,
footer .wrapper2 section {
    width: 100%;
    margin-bottom: 2em;
  }
}
.nav_sp .wrapper2 section.footer_profile,
footer .wrapper2 section.footer_profile {
  width: 35%;
  padding: 0 10% 0 0;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
@media (max-width: 1024px) {
  .nav_sp .wrapper2 section.footer_profile,
footer .wrapper2 section.footer_profile {
    width: 25%;
  }
}
@media (max-width: 767px) {
  .nav_sp .wrapper2 section.footer_profile,
footer .wrapper2 section.footer_profile {
    width: 100%;
    margin-bottom: 2em;
  }
}
.nav_sp .wrapper2 section .footer_nav_work, .nav_sp .wrapper2 section .work_contents_link2,
footer .wrapper2 section .footer_nav_work,
footer .wrapper2 section .work_contents_link2 {
  display: block;
  font-size: 0.875rem;
}
.nav_sp .wrapper2 section .footer_nav_work + *, .nav_sp .wrapper2 section .work_contents_link2 + *,
footer .wrapper2 section .footer_nav_work + *,
footer .wrapper2 section .work_contents_link2 + * {
  margin-top: 1.25rem;
}
.nav_sp .wrapper2 section .work_contents_link2,
footer .wrapper2 section .work_contents_link2 {
  font-size: 0.775rem;
}
.nav_sp .wrapper2 section .work_contents_link,
footer .wrapper2 section .work_contents_link {
  display: block;
  font-size: 0.775rem;
}
.nav_sp .wrapper2 section .work_contents_link:not(:last-child),
footer .wrapper2 section .work_contents_link:not(:last-child) {
  margin-bottom: 0.5rem;
}
@media (max-width: 1024px) {
  .nav_sp .wrapper2 section .work_contents_link,
footer .wrapper2 section .work_contents_link {
    text-indent: 0;
  }
}
@media (max-width: 767px) {
  .nav_sp .wrapper2 section .work_contents_link,
footer .wrapper2 section .work_contents_link {
    margin-left: 1.5em;
  }
}
.nav_sp .wrapper2 section.footer_works_last,
footer .wrapper2 section.footer_works_last {
  position: relative;
}
.nav_sp .wrapper2 section.footer_works_last .copyright,
footer .wrapper2 section.footer_works_last .copyright {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 599px) {
  .nav_sp .wrapper2 section.footer_works_last,
footer .wrapper2 section.footer_works_last {
    margin-bottom: 0;
  }
}

.nav_sp {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 15;
  width: 0;
  min-height: 100vh;
  background: black;
  overflow: hidden;
}
.nav_sp .wrapper2 {
  align-items: flex-start;
}
.nav_sp a {
  color: #FF4528;
}
.nav_sp.open {
  right: 0;
  width: calc((100% / 5) * 2);
}
@media (max-width: 767px) {
  .nav_sp.open {
    width: 100%;
  }
}

footer {
  margin-top: 9rem;
}
@media (max-width: 767px) {
  footer {
    margin-top: 4.5rem;
  }
}

.logo_main {
  width: 10rem;
  transform: translateY(-0.0125rem);
}
@media (max-width: 767px) {
  .logo_main {
    width: 16vw;
    max-width: 130px;
  }
}
@media (max-width: 599px) {
  .logo_main {
    width: 38vw;
    max-width: 108px;
  }
}

.work_cat_title {
  font-family: quasimoda, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 36px;
  letter-spacing: 0, 1rem;
  margin: 0 auto -96px;
}
@media (max-width: 767px) {
  .work_cat_title {
    margin: 0 auto -28px;
    font-size: 4.35vw;
  }
}
@media (max-width: 599px) {
  .work_cat_title {
    font-size: 5.35vw;
  }
}
.work_cat_title a:not(:first-child):before {
  content: "/";
  margin: 0 16px;
  color: #000;
  transition: none;
}
.work_cat_title a:not(:first-child):hover::before {
  color: #000;
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(3, calc((100% / 3) - 1.133rem));
  column-gap: 1.75rem;
  row-gap: 3rem;
  width: 100%;
}
@media (max-width: 599px) {
  .grid1 {
    grid-template-columns: repeat(1, 100%);
    column-gap: 0;
  }
}
.grid1 .grid_item {
  margin: 0;
}
.grid1 .grid_item .work_title {
  font-family: quasimoda, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.04em;
  margin: 1.15rem 0 0;
}
.grid1 .grid_item .images img {
  object-fit: cover;
  aspect-ratio: 369/235;
}

.list_item h1 {
  font-family: quasimoda, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 4.1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.009em;
  position: absolute;
  top: -3.15rem;
  left: 0;
  z-index: 5;
  width: 100%;
  padding: 0 calc(((100% - 40rem) / 100 * 25) - 3rem) 0 calc(((100% - 40rem) / 100 * 40) + 6.25rem);
  text-align: center;
}
@media (max-width: 599px) {
  .list_item h1 {
    display: none;
  }
}
.list_item h1 + * {
  margin-top: -1.5vw;
}
@media (max-width: 599px) {
  .list_item h1 + * {
    margin-top: -3vw;
  }
}
.list_item .work_title {
  font-family: quasimoda, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.04em;
  margin: 1.15rem 0 0;
}
.list_item a {
  display: block;
  position: relative;
}
.list_item a .trim {
  transition: all 0.5s ease;
  position: relative;
  top: 0;
}
.list_item a:hover .trim {
  top: -5px;
}
.list_item a:hover h1 {
  color: #fff;
}
.list_item .images img {
  transition: filter 0.3s;
}
.list_item .images img.lazyestload {
  width: 100%;
  filter: blur(7px);
}

.single {
  font-size: 1rem;
  line-height: 1.5;
  /*------------------------------------
  		newsletter
  ------------------------------------*/
}
.single h2 {
  font-weight: 400;
  letter-spacing: 0.04em;
  font-family: quasimoda, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  margin: 1.5rem 0;
}
@media (min-width: 768px) {
  .single h2 {
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.single h2:first-child {
  margin: 0 0 1.5rem;
}
.single .block:not(:first-child) {
  margin: -64px 0 0;
  padding: 128px 0 0;
  width: 50%;
}
@media (max-width: 767px) {
  .single .block:not(:first-child) {
    width: 100%;
  }
}
.single .block h1 {
  margin: 0 0 32px;
}
.single .block::after {
  content: "";
  display: block;
  clear: both;
}
.single .flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.single .flex > * {
  width: calc(50% - 24px);
}
@media (max-width: 767px) {
  .single .flex > * {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .single .indent1 {
    transform: translate(-7.5rem, 0.25rem);
  }
}
.single .left {
  float: left;
  margin: 0;
  width: 5.5rem;
}
.single .right {
  margin: 0 0 0 7.5rem;
}
.single p,
.single .content > div {
  margin: 0 0 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.04em;
  line-height: 1.6;
}
.single .content > div:last-of-type:not(.right, .left) {
  margin: 0 0 2rem;
}
.single p:last-child {
  margin-bottom: 0;
}
.single .intro, body:lang(ja) .single .intro {
  font-family: baskerville-urw, "Times New Roman", Georgia, "Hiragino Mincho ProN", 游明朝, "Yu Mincho", YuMincho, HGS明朝E, serif;
  line-height: 1.5;
  font-size: 2rem;
}
@media (max-width: 767px) {
  .single .intro, body:lang(ja) .single .intro {
    font-size: 5vw;
  }
}
.single .images:not(:first-child) {
  margin: 1.5rem 0 0;
}
.single .images img {
  width: 100%;
  transition: filter 0.3s;
}
.single .images img:not(:first-child) {
  margin: 1.5rem 0 0;
}
.single .images img.lazyestload {
  width: 100%;
  filter: blur(7px);
}
.single .images + .videos {
  margin: 1.5rem 0 0;
}
.single .img img {
  width: 100%;
}
.single .achievement {
  margin-top: 3.75rem;
}
@media (max-width: 767px) {
  .single .achievement {
    margin-top: 0;
  }
}
.single .achievement > section {
  margin-top: 4rem;
}
@media (max-width: 767px) {
  .single .achievement > section {
    margin-top: 1.5rem;
  }
}
.single .achievement > section > * {
  width: calc(66.6% - 1rem);
}
.single .achievement > section > *:first-child {
  width: calc(33.3% - 2rem);
}
@media (max-width: 767px) {
  .single .achievement > section > * {
    width: 100%;
    margin: 1.5rem 0 0;
  }
  .single .achievement > section > *:first-child {
    width: 100%;
  }
}
.single .achievement h2 {
  font-family: nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
}
.single .achievement h2,
.single .achievement div,
.single .achievement p {
  font-size: 1.125rem;
  line-height: 1.75;
  letter-spacing: 0.04em;
}
.single .achievement h2:last-child:first-child,
.single .achievement div:last-child:first-child,
.single .achievement p:last-child:first-child {
  margin: 0;
}
@media (max-width: 767px) {
  .single .achievement h2,
.single .achievement div,
.single .achievement p {
    font-size: 1rem;
  }
}
.single .achievement .txt_s h2,
.single .achievement .txt_s div,
.single .achievement .txt_s p {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.65rem;
}
.single .newsletter {
  margin: 3rem auto;
}
@media (max-width: 599px) {
  .single .newsletter {
    width: 100%;
  }
}
.single .newsletter h2 {
  font-size: 1.125rem;
  line-height: 1.75;
}
@media (max-width: 767px) {
  .single .newsletter h2 {
    font-size: 1rem;
  }
}
.single .newsletter .flex > * {
  width: calc(66.6% - 1rem);
}
.single .newsletter .flex > * br {
  display: none;
}
.single .newsletter .flex > *:first-child {
  width: 33.3%;
}
.single .newsletter .flex > *:last-child {
  padding: 0 calc(33.3% + 1rem) 1.5rem 0;
  margin: -2.5rem 0 0;
}
.single .newsletter .flex > * div {
  padding: 0;
}
@media (max-width: 767px) {
  .single .newsletter .flex > * {
    width: 100%;
    margin: 1.5rem 0 0;
  }
  .single .newsletter .flex > *:first-child {
    width: 100%;
  }
  .single .newsletter .flex > *:last-child {
    padding: 0;
    margin: 0;
  }
}
.single .newsletter input {
  border: none !important;
  border-bottom: 1px solid #707070 !important;
  font-family: nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.07rem !important;
  margin: 0 !important;
  padding: 1.25rem 0 !important;
}
.single .newsletter fieldset {
  padding: 0 !important;
}
.single .newsletter fieldset div {
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #000 !important;
  margin: 0.5rem 0 !important;
  padding: 0.75rem 0 !important;
  font-family: nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
  text-align: left !important;
  font-size: 0.875rem !important;
}
.single .newsletter fieldset div:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.single #register {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.single #register .wrapper2 {
  margin: 0 auto;
  margin-top: 0 !important;
}
@media (max-width: 599px) {
  .single #register .wrapper2.single {
    padding: 0;
  }
}
.single.news {
  padding-top: 3rem;
}
.single.news h2 {
  font-family: nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
}
.single.news .child {
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .single.news .child {
    display: flex;
    margin-bottom: 3rem;
  }
}
.single.news .child .date {
  margin-top: 0.325rem;
  width: 15%;
}
@media (max-width: 767px) {
  .single.news .child .date {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.single.news .child .text {
  width: 40%;
}
@media (max-width: 767px) {
  .single.news .child .text {
    width: 100%;
    margin-bottom: 4vw;
  }
}
@media (max-width: 599px) {
  .single.news .child .text {
    margin-bottom: 6vw;
  }
}
.single.news .child .img {
  width: 45%;
  padding-left: 3rem;
  transform: translateY(5px);
}
@media (max-width: 767px) {
  .single.news .child .img {
    width: 100%;
    padding-left: 0;
  }
}
.single.news .child .img img {
  width: 100%;
}
.single.news .child p,
.single.news .child div {
  margin-bottom: 1.5rem;
}
.single.news .child p.ja,
.single.news .child div.ja {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
}
.single.news .child p a,
.single.news .child div a {
  text-decoration: underline;
}

.home {
  background: #FF4528;
  overflow: hidden;
}
.home header {
  background: #FF4528;
}
.home .loading {
  opacity: 1;
  background: url(../img/logo_spread_bk.svg) no-repeat 50% 50%;
  background-size: 48%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: all 0.5s ease;
}
.home.loaded {
  overflow: auto;
}
.home.loaded .loading {
  background-position-y: 52.5%;
  opacity: 0;
}
.home #background,
.home .background {
  position: relative;
  padding: calc(360 / 566 * 100%) 0 0;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 48px;
}
@media (max-width: 767px) {
  .home #background,
.home .background {
    margin-bottom: 40px;
  }
}
.home #background iframe,
.home .background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home .list_item {
  margin-top: 9rem;
}
@media (max-width: 767px) {
  .home .list_item {
    margin-top: 5rem;
  }
}
@media (max-width: 599px) {
  .home .list_item {
    margin-top: 1rem;
  }
}
@media (max-width: 599px) {
  .home .list_item:nth-of-type(4) h1, .home .list_item:nth-of-type(5) h1, .home .list_item:nth-of-type(6) h1 {
    text-align: left;
  }
  .home .list_item:nth-of-type(7), .home .list_item:nth-of-type(8), .home .list_item:nth-of-type(9) {
    margin-top: -0.25rem;
  }
  .home .list_item:nth-of-type(7) h1, .home .list_item:nth-of-type(8) h1, .home .list_item:nth-of-type(9) h1 {
    text-align: left;
    position: static;
    margin-bottom: 0.75rem;
  }
  .home .list_item:nth-of-type(7) h1 + *, .home .list_item:nth-of-type(8) h1 + *, .home .list_item:nth-of-type(9) h1 + * {
    margin-top: 0;
  }
}

.biography .intro + *, .biography body:lang(ja) .single .intro + *, body:lang(ja) .single .biography .intro + * {
  margin: 3rem 0 0;
}
.biography .intro + *.flex, .biography body:lang(ja) .single .intro + *.flex, body:lang(ja) .single .biography .intro + *.flex {
  margin: 4rem 0 0;
}
@media (max-width: 767px) {
  .biography .intro + *.flex, .biography body:lang(ja) .single .intro + *.flex, body:lang(ja) .single .biography .intro + *.flex {
    margin: 3rem 0 0;
  }
}
.biography .flex > * {
  width: calc(33.3% - 2rem);
  font-size: 0.875rem;
}
@media (max-width: 767px) {
  .biography .flex > * {
    width: 100%;
  }
  .biography .flex > *:not(:first-child) {
    margin: 1.5rem 0 0;
  }
}
.biography img {
  width: 100%;
  object-fit: cover;
}

.contact .flex > * {
  width: calc(33.3% - 2rem);
  font-size: 0.875rem;
}
@media (max-width: 767px) {
  .contact .flex > * {
    width: 100%;
    margin: 1.5rem 0 0;
  }
}
.contact .flex h2 {
  font-size: 1.125rem;
  line-height: 1.75;
}
@media (max-width: 767px) {
  .contact .flex h2 {
    font-size: 1rem;
  }
}

.pagenavi {
  display: none;
}

.archive_work {
  font-size: 4vw;
  letter-spacing: 0.1vw;
}
.archive_work .group {
  overflow-x: hidden;
}
@media (max-width: 767px) {
  .archive_work .group {
    width: 100%;
  }
}
.archive_work .group > nav {
  padding: 0 0 0 4vw;
}
.archive_work .group > nav p,
.archive_work .group > nav ul {
  width: 100%;
}
@media (min-width: 768px) {
  .archive_work .group > nav p {
    width: 45%;
  }
}
.archive_work .group > nav ul {
  display: none;
  overflow: scroll;
  transition: all 0.5s ease;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .archive_work .group > nav ul {
    font-size: 3.4vw;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    padding-right: 6vw;
    width: 55%;
    height: calc(100vh - .5rem);
  }
}
@media (max-width: 767px) {
  .archive_work .group > nav ul {
    width: 100%;
    margin: 0 0 0 4vw;
    display: block;
    max-height: 0;
    opacity: 0;
  }
}
@media (min-width: 768px) {
  .archive_work .group > nav:hover ul {
    display: block;
  }
  .archive_work .group > nav:hover p {
    color: #fff;
  }
  .archive_work .group > nav a:hover {
    color: #fff;
  }
}
@media (max-width: 767px) {
  .archive_work .group > nav.selected ul {
    opacity: 1;
    max-height: 100vh;
    transition: 1s all ease-out;
  }
}
.archive_work h1 {
  cursor: pointer;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .archive_work h1 {
    margin-bottom: 1.5vw;
  }
}
@media (max-width: 599px) {
  .archive_work h1 {
    margin-bottom: 3vw;
  }
}
a.more {
  font-family: quasimoda, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  color: #9fa0a0;
  display: block;
}
a.more:hover {
  color: black;
}

span.more {
  display: none;
}
span.more.visible {
  display: inline !important;
  animation-duration: 3s;
  animation-name: fadeIn;
}
span.more.visible + .more {
  margin-top: 1rem;
}

.video {
  padding-bottom: 56.25%;
  /* for 16:9 ratio */
  /*640px x 407px*/
  position: relative;
  height: 0;
  background: #000;
}
.video > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: inline !important;
    opacity: 0;
  }
  100% {
    display: inline !important;
    opacity: 1;
  }
}
.qa .qa_lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .qa .qa_lists {
    display: block;
  }
}
.qa .qa_lists .qa_block {
  width: calc(50% - 48px);
  min-height: 8rem;
}
@media (max-width: 800px) {
  .qa .qa_lists .qa_block {
    width: 100%;
    min-height: auto;
    margin-bottom: 32px;
  }
}
.qa .qa_lists .qa_block .qa_q {
  margin: 96px 0 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: auto;
}
.qa .qa_lists .qa_block .qa_q:first-child {
  margin-top: 0;
}
.qa .qa_lists .qa_block .qa_q:hover .qa_arrow {
  transform: translateY(8px);
}
.qa .qa_lists .qa_block .qa_q .dt_q {
  display: inline-block;
  font-family: baskerville-urw, "Times New Roman", Georgia, "Hiragino Mincho ProN", 游明朝, "Yu Mincho", YuMincho, HGS明朝E, serif;
  line-height: 1.5;
  font-size: 40px;
  text-align: right;
  vertical-align: top;
  transform: translate(0, -16px);
}
@media (max-width: 599px) {
  .qa .qa_lists .qa_block .qa_q .dt_q {
    font-size: 3.96vw;
    width: 6vw;
    transform: translate(-5px, -3px);
  }
}
.qa .qa_lists .qa_block .qa_q .dt_txt {
  display: inline-block;
  font-size: 18px;
  letter-spacing: 0.1rem;
  width: calc(100% - 128px);
  text-transform: uppercase;
}
@media (max-width: 599px) {
  .qa .qa_lists .qa_block .qa_q .dt_txt {
    font-size: 3.96vw;
    width: calc(100% - 10vw);
  }
}
.qa .qa_lists .qa_block .qa_q .qa_arrow {
  position: relative;
  top: 0;
  width: 24px;
  right: 0;
  align-self: self-start;
}
.qa .qa_lists .qa_block .qa_q .qa_arrow.qa_arrow_active {
  transform: rotate(180deg);
}
@media (max-width: 800px) {
  .qa .qa_lists .qa_block .qa_q .qa_arrow {
    top: 0.5vh;
    width: 3vw;
  }
}
.qa .qa_lists .qa_block .qa_a {
  display: none;
  margin-bottom: 32px;
  transition-property: none;
}
.qa .qa_lists .qa_block .qa_a .dd_txt {
  font-size: 16px;
  letter-spacing: 0.1rem;
  display: inline-block;
  padding: 0 0 0 72px;
}
@media (max-width: 599px) {
  .qa .qa_lists .qa_block .qa_a .dd_txt {
    font-size: 3.96vw;
    width: calc(100% - 11vw);
    padding-left: 9vw;
  }
}

body:lang(ja) {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-weight: 200;
}
body:lang(ja) .intro, body:lang(ja) .single .intro {
  font-family: "A1 Mincho", "Hiragino Mincho ProN", 游明朝, "Yu Mincho", YuMincho, HGS明朝E, serif;
  line-height: 1.5;
  font-size: 1.75rem;
  font-weight: normal;
  line-height: 2;
  font-size: 1.55rem;
}
@media (max-width: 599px) {
  body:lang(ja) .intro, body:lang(ja) .single .intro {
    font-size: 1rem;
  }
}
@media (max-width: 599px) {
  body:lang(ja) .intro, body:lang(ja) .single .intro {
    font-size: 0.95rem;
  }
}
body:lang(ja) .single p,
body:lang(ja) .single .content > div {
  font-size: 0.8rem;
  line-height: 1.875;
}
body:lang(ja) .single .achievement h2,
body:lang(ja) .single .achievement p,
body:lang(ja) .single .achievement div {
  font-size: 1rem;
}
body:lang(ja) .single .achievement .txt_s h2,
body:lang(ja) .single .achievement .txt_s p,
body:lang(ja) .single .achievement .txt_s div {
  font-size: 0.75rem;
}
body:lang(ja) header .head_items a {
  font-family: nimbus-sans, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 0.825rem;
}
body:lang(ja) header .head_items .lang-sel {
  font-weight: 300;
}
body:lang(ja) .qa dl .qa_a .dd_txt {
  font-size: 1.85vw;
  line-height: 1.75;
}
@media (max-width: 599px) {
  body:lang(ja) .qa dl .qa_a .dd_txt {
    font-size: 3.5vw;
  }
}